// Breakpoint reporter/debugger.
// ---------------------------------------------------------

#breakpoint-reporter {
    display: none;
    font-family: sans-serif;

    @each $breakpoint, $width in $mq-breakpoints {

        @include mq($breakpoint) {
            font-family: sans-serif, "#{$breakpoint}";

            &::after {
                content: "#{$breakpoint} ≥ #{$width} (#{mq-px2em($width)})";
            }
        }
    }
}

.debug #breakpoint-reporter {
    @include position(fixed, null 5px 5px null);
    background: #fff;
    box-shadow: 0 0 5px rgba(#000, .5);
    display: block;
    font-size: 10px;
    opacity: .8;
    padding: .5em 1.0em;
    pointer-events: none;
    z-index: 999999;

    &.did-change {
        background: #fcd753;
    }
}

// Collapsible styles
// ---------------------------------------------------------
@mixin collapsible($iconSize: 14px, $breakpoint: 'tablet') {
    .collapsible__content {
        display: block;
    }

    .icon--collapsible {
        @include size($iconSize);

        display: none;
        opacity: 1;
        position: absolute;
        right: 0;
        top: calc(50% - (#{$iconSize} / 2));
        transition: opacity .3s;
    }

    @include mq($until: $breakpoint) {
        .collapsible__title {
            padding-right: $iconSize * 1.5;
            position: relative;

            .icon--collapsible {
                display: block;
            }
        }

        .collapsible__item {
            border-bottom: 2px solid $mystic-blue;

            &.active {
                .icon--collapsible--collapsed {
                    opacity: 0;
                }
            }
        }
    }
}


.custom-modal {
    @include modal;
    @include modal--skin-default;
}

.modal {
    @include position(fixed, 0 null null 0);
    @include size(100vw 100vh);
    perspective: 1000px;
    z-index: 9999;

    .layout-wrapper {
        border-left-width: 3vw;
        border-right-width: 3vw;
    }

    &__overlay {
        @include size(100%);
        background: rgba($black, .8);
        opacity: 1;
    }

    &__loading {
        @include position(absolute, 50% null null 50%);

        animation-delay: .1s;
        display: flex;
        margin: -6px;

        .modal__loading-dot {
            @include size(12px);

            animation-name: dots;
            animation-duration: 1.4s;
            animation-iteration-count: infinite;
            animation-fill-mode: both;

            background: $white;
            border-radius: 50%;

            &.dot-2 {
                animation-delay: .1s;
                margin: 0 6px;
            }

            &.dot-3 {
                animation-delay: .2s;
            }
        }

        @keyframes dots {
            0% {
                opacity: .2;
            }
            20% {
                opacity: 1;
            }
            100% {
                opacity: .2;
            }
        }
    }

    &__dialog {
        @include position(absolute, 0 null null 0);
        @include size(100%);
        align-items: center;
        display: flex;
        justify-content: center;
        pointer-events: none;
        z-index: 5;
    }

    &__body {
        align-items: stretch;
        background: $white;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        max-height: $modal-max-height;
        max-width: $modal-max-width;
        pointer-events: auto;
        position: relative;
    }

    &__document {
        overflow: auto;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    &__close {
        @include position(absolute, 0 0 null null);
        @include size(60px);
        background: transparent;
        border: 0 none;
        border-radius: 15px;
        color: $mountbatten-pink;
        cursor: pointer;
        outline: 0 none !important;
        padding: 0;
        transition: color $hover-duration $hover-timing-function;
        z-index: 900;

        &:focus,
        &:hover {
            color: $negative-color;
        }

        .icon {
            @include size(24px);
        }
    }

    // Transitions

    &.is-hidden,
    &.is-hidden &__overlay,
    &__loading.is-hidden,
    &__dialog.is-hidden {
        display: none;
    }

    &__overlay,
    &__dialog {
        transition-property: opacity, transform;
        transition-duration: 0.3s;
    }

    &.is-entering &__overlay,
    &.is-leaving &__overlay {
        opacity: 0;
    }

    &__dialog.is-entering {
        opacity: 0;
        transform: translate3d(0, 0, 60px);
    }

    &__dialog.is-leaving {
        opacity: 0;
        transform: translate3d(0, 0, -60px);
        z-index: 4;
    }
}

html.modal-active,
html.modal-active body {
    overflow: hidden;
}

// Responsive styles
@include mq($until: phone-l) {
    .modal {
        &__body,
        &__document {
            border-radius: 0;
            height: 100vh;
            max-height: 100vh;
            max-width: 100vw;
            width: 100vw;
        }

        &__close {
            @include size(40px);

            background: $chestnut-red;
            border-radius: 0;
            color: $white;
            right: 0;
        }
    }
}

// Colour palette.
// ---------------------------------------------------------

$white: #fff;

$vista-white: #f8ecee;

$mischka-gray: #dedde4;

$silver-chalice-gray: #aeaeae;

$black: #000;

$mirage-blue: #121227;

$thunder-gray: #231f20;

$mineshaft-gray: #2d2d2d;

$cocoa-bean-brown: #411919;

$desert-brown: #a2681e;

$coffee-brown: #736755;

$wine-berry-purple: #471341;

$eggplant-purple: #6c4267;

$old-lavender-purple: #7e5a7a;

$mountbatten-pink: #a389a0;

$camelot-red: #893045;

$chestnut-red: #b74553;

$flamingo-red: #eb5c36;

$contessa-red: #c56a75;

$can-can-red: #dca4ab;

$apricot-peach: #fbd4c0;

$crete-green: #607730;

$regal-blue: #04416a;

$paradiso-blue: #2f6d7f; // sometimes #2e6c7e

$waterloo-blue: #878698;

$horizon-blue: #598a99;

$nepal-blue: #96b5be; // sometimes #97b6bf

$jungle-mist-blue: #b2c9d0; //sometimes #acc5cc, #c1d3d9

$geyser-blue: #d5e2e5;

$mystic-blue: #ecf2f3; // sometimes #eaf0f2


// Named colours.
// ---------------------------------------------------------

$brand-color: $paradiso-blue;
$brand-color--dark: $wine-berry-purple;

$action-color: $chestnut-red;
$accent-color: $brand-color;

$positive-color: $chestnut-red;

$negative-color: $contessa-red;

// Typography settings.
// ---------------------------------------------------------

$font-family: 'Open Sans';
$font-family--sans: 'Open Sans';

$font-size: 16px;
$font-size--l: 24px;
$font-size--m: 14px;
$font-size--s: 12px;

$font-weight: 400;
$font-weight--light: 300;
$font-weight--bold: 700;

$line-height: (20px / 16px) + .0001;
$line-height--l: (24px / 16px) + .0001;
$line-height--s: (19px / 16px) + .0001;

$text-color: $brand-color--dark;
$text-color--strong: $white;

$link-color: $brand-color;
$link-color--active: $action-color;

// Component variables.
// ---------------------------------------------------------
$box-padding-top: $margin-size--m;
$box-padding-bottom: $margin-size--m;
$box-padding-side: $margin-size--s * 1.6;

// Modal box structure.
// ---------------------------------------------------------
.ui-widget-overlay {
    background: $brand-color--dark;
    opacity: .9;
}

@mixin modal() {
    &__title {
        font-family: $font-family;
        font-size: $font-size--l * 1.5;
        font-weight: $font-weight--bold;
        line-height: $line-height--l * 1.5;
    }

    &__buttonset {
        float: left !important;
        width: 100%;

        .ui-button:last-child {
            float: right !important;
        }
    }

    &__close {
        @include visuallyhidden();
    }

    &__content {
        .form {
            &__row {
                &:last-of-type {
                    @include visuallyhidden();
                }
            }

            &__field-wrapper {
                width: 100%;
            }
        }
    }
}

// Details box skins.
// ---------------------------------------------------------
@mixin modal--skin-default() {
    padding: 0;
    border: none !important;

    &__content,
    &__titlebar,
    &__buttons {
        //background: $mineshaft-gray;
        border: none;
        margin-top: 0 !important;
        padding: $margin-size--s * 1.2 $margin-size--m !important;

        .ui-button {
            @include button();
            @include button--size-default();
            @include button--skin-default();

            &.update {
                @include button--skin-color($accent-color);
            }
            &.cancel {
                //@include button--skin-color($cod-gray);
            }
        }
    }

    &__overlay {
        //background: $mineshaft-gray;
        opacity: .9;
    }
}

// Details box modifiers.
// ---------------------------------------------------------

// Button structure.
// ---------------------------------------------------------

@mixin button {
    @include text-overflow;
    align-items: center;
    border: 0 none;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    font-family: $font-family;
    font-weight: $font-weight;
    outline: 0 none !important;
    justify-content: center;
    text-align: center;
    text-decoration: none !important;
    text-transform: uppercase;
    transition-property: background-color, border-color, color, opacity, transform, $focus-property;
    transition-duration: $hover-duration;
    transition-timing-function: $hover-timing-function;
    vertical-align: top;

    &.disabled {
        cursor: default;
        opacity: .4;
        pointer-events: none;
    }

    .icon {
        @include size(24px);
        margin-left: $margin-size--s * (2 / 3);
    }
}

// Button sizing.
// ---------------------------------------------------------

@mixin button--size($height, $font-size: null) {
    $default-font-size: 16px;

    @if $font-size == null {
        $font-size: $default-font-size;
    }

    font-size: $font-size;
    height: $height;

    padding: 11px 20px;
}

@mixin button--size-default {
    $default-button-height: 40px;
    @include button--size($height: $default-button-height);
}

@mixin button--size-s {
    @include button--size($height: $widget-size--s);
}

@mixin button--size-l {
    @include button--size($height: $widget-size--l);
}

@mixin button--size-mobile {
    @include button--size(52px, 20px);

    border-radius: 26px;
    width: 100%;
}

// Button colouration.
// ---------------------------------------------------------

@mixin button--skin-color($background-color, $text-color: null, $hover-color: null) {

    @if $text-color == null {
        $text-color: contrasting-color($background-color, $brand-color--dark, $white);
    }

    @if $hover-color == null {
        $hover-color: darkest-color($background-color + $hover-tint, mix($background-color, $white));
    }

    background: $background-color;
    color: $text-color;

    &:active,
    &:focus,
    &:hover {
        background: $hover-color;
        color: $text-color;
    }
}

@mixin button--skin-ghost($background-color, $text-color) {

    background: none;
    border: 2px solid $text-color;
    color: $text-color;

    &:active,
    &:focus,
    &:hover {
        background: $text-color;
        color: $background-color;
    }
}

@mixin button--skin-default {
    @include button--skin-color($action-color);

    &:hover {
        background: $camelot-red;
    }
}

@mixin button--skin-primary {
    @include button--skin-color($accent-color);

    .icon {
        color: $white;
    }
}

@mixin button--skin-secondary {
    @include button--skin-color($white);

    .icon {
        color: $action-color;
    }
}

@mixin button--skin-faded--red {
    @include button--skin-color($vista-white);

    color: $action-color;
    font-weight: $font-weight--bold;

    .icon {
        color: $action-color;
    }

    &:hover {
        background: $action-color;
        color: $white;

        .icon {
            color: $white;
        }
    }
}

@mixin button--skin-ghost--white {
    @include button--skin-ghost($brand-color, $white);
}

@mixin button--skin-ghost--red {
    @include button--skin-ghost($mystic-blue, $action-color);

    border-width: 3px;
    font-weight: $font-weight--bold;
}

.pricing-block {
    padding: 0;
    text-align: center;

    .layout-wrapper {
        position: relative;
    }

    &__information {
        display: flex;
        flex-direction: column;
        padding: 50px 0;
    }

    &__title {
        font-size: 24px;
        line-height: 1.3;
        margin-bottom: 20px;
    }

    &__subtitle {
        color: $eggplant-purple;
        font-size: 18px;
        line-height: 1.4;
        margin-bottom: 16px;
        padding: 0 270px;
    }

    &__price {
        font-size: 58px;
        font-weight: $font-weight--bold;
        line-height: 1;
    }

    &__price-vat {
        color: $eggplant-purple;
        font-size: 16px;
        margin-bottom: 26px;

        & > p {
            display: inline-block;
        }
    }

    &__button {
        align-self: center;
    }

    &__image {
        @include position(absolute, null 80px 0 null);

        .icon {
            @include size(192px 342px);

            opacity: .5;
        }
    }
}

// Responsive styles
@include mq($until: desktop) {
    .pricing-block {
        &__image {
            display: none;
        }

        &__subtitle {
            padding: 0 200px;
        }
    }
}

@include mq($until: desktop) {
    .pricing-block {
        &__subtitle {
            padding: 0 100px;
        }
    }
}

@include mq($until: tablet) {
    .pricing-block {
        &__information {
            padding-top: 40px;
        }

        &__title {
            font-size: 22px;
        }

        &__subtitle {
            font-size: 19px;
            padding: 0;
        }

        &__button {
            @include button--size-mobile;
        }
    }
}

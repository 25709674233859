// Form structure.
// ---------------------------------------------------------

@mixin form() {
    @include form-validation();

    text-align: left;

    &__title {
        font-size: $font-size--l * 2;
        margin-bottom: $margin-size;
    }

    &__head {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 36px;

        .form__required {
            font-size: $font-size--m;
            left: -12px;
            right: unset;
        }
    }

    &__compound {
        align-items: flex-end;
        display: flex;

        .form__row {
            flex: 1 1 auto;

            input {
                padding-right: 46px;
                width: 100%;
            }
        }

        .form__row + .form__row {
            margin-left: $margin-size--s * (1 / 2);
            margin-top: 0;
        }
    }

    &__row + &__row,
    &__row + &__compound,
    &__compound + &__row,
    &__compound + &__compound,
    &__errors + &__row,
    &__errors + &__compound,
    &__force-margin {
        margin-top: 12px;
    }

    &__row {
        align-items: center;
        display: flex;
        width: 100%;

        &[data-form-type*='ewz_recaptcha'] {
            margin: 20px 0;

            .form__label__text {
                visibility: hidden;
            }
        }
    }

    &__label {
        color: $horizon-blue;
        display: flex;
        font-size: $font-size--m;
        max-width: 516px;
        width: 516px;
    }

    &__label__text {
        margin-right: 16px;
        margin-top: 18px;
        min-width: 100px;
        text-align: right;
        width: 100px;

        .optional {
            color: $jungle-mist-blue;
            display: block;
            margin-top: 4px;
        }
    }

    &__field-wrapper {
        margin-right: 18px;
        position: relative;
    }

    &__input,
    &__select,
    &__textarea {
        font-weight: $font-weight;
        width: 100%;
    }

    &__row--checkbox {
        .form__label {
            align-items: center;
            margin-left: 116px;
        }

        .form__label__text {
            order: 1;
            margin-top: 0;
            margin-left: 4px;
        }
    }

    &__checkbox {
        width: auto;
    }

    .form__input-wrapper--checkbox {
        @include form--field-checkbox;
    }

    &__select--wrapper {
        position: relative;

        .icon {
            @include size(12px);

            pointer-events: none;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translate(0, -50%);
        }
    }

    &__button {
        margin-left: 116px;
        margin-top: 12px;
    }

    // < desktop
    @include mq($until: desktop) {
        &__row,
        &__compound {
            flex-wrap: wrap;
            height: auto;
            width: 100%;
        }
    }

    // < tablet
    @include mq($until: tablet) {
        padding: 0;

        &__row + &__row {
            margin-top: 6px;
        }

        &__row {
            &[data-form-type*='ewz_recaptcha'] {
                margin-bottom: 12px;
                margin-top: 12px;
                transform: scale(.94);
                transform-origin: 0 50%;

                .form__label__text {
                    @include visuallyhidden;
                }
            }
        }

        &__label {
            display: block;
            width: 100%;
        }

        &__label__text {
            margin-bottom: 4px;
            margin-right: 0;
            margin-top: 0;
            text-align: left;

            .optional {
                display: inline;
                margin-left: 4px;
                margin-top: 0;
            }

            br {
                display: none;
            }
        }

        &__button {
            margin-left: 0;
        }
    }
}

// Form layout variations.
// ---------------------------------------------------------

@mixin form--full-width-labels() {
    &__label {
        flex-wrap: wrap;
    }

    &__label__text {
        margin-bottom: 4px;
        margin-right: 0;
        text-align: left;
        width: 100%;
    }

    &__row--buttons,
    &__row--checkbox,
    &__errors,
    &__password-reset {
        margin-left: 0;
    }
}

@mixin form--full-width() {
    @include form--full-width-labels;

    &__compound {
        flex-wrap: wrap;

        .form__row + .form__row {
            margin-left: 0;
        }
    }

    &__field-wrapper {
        margin-right: 26px;
        width: 100%;

        &.form__select--wrapper {
            width: auto;
        }
    }

    &__label,
    &__input,
    &__textarea {
        text-align: left;
        max-width: 100%;
        min-width: 100%;
        width: 100%;
    }

    &__row--buttons,
    &__row--checkbox,
    &__errors {
        margin-left: 0 !important;
        max-width: 100%;
        width: 100%;
    }

    &__button {
        @include button--size-default;

        width: 100%;
    }
}

// Form label variations.
// ---------------------------------------------------------

@mixin form--label-width($width: 160px, $gap: 10px) {

    &__label {
        margin-right: $gap;
        min-width: $width;
        width: $width;
    }

    &__row--buttons,
    &__row--checkbox,
    &__errors,
    &__password-reset {
        margin-left: $width + $gap;
    }

}

// Form field variations.
// ---------------------------------------------------------

@mixin form--field-checkbox() {
    align-items: center;
    flex-wrap: wrap;
    display: flex;

    .form__label__text {
        color: $brand-color--dark;
        flex-grow: 1;
        font-size: 14px;
        margin: 0 0 0 30px;
        min-width: auto;
        text-align: left;
        width: auto;
    }

    .form__input {
        @include hidden;

        & + .form__label {
            align-items: center;
            cursor: pointer;
            display: flex;
            height: 24px;
            position: relative;
            user-select: none;

            .icon {
                @include position(absolute, 0 null null 0);
                @include size(24px);
            }

            .icon--form__checkbox-checked {
                display: none;
            }
        }

        &:checked {
            & + .form__label {
                .icon--form__checkbox-checked {
                    display: block;
                }

                .icon--form__checkbox-unchecked {
                    display: none;
                }
            }
        }
    }

    // Checkbox responsive styles
    @include mq($until: tablet) {
        .form__label__text {
            margin: 0 0 0 42px;
        }

        .form__input {
            & + .form__label {
                height: 36px;

                .icon {
                    @include size(36px);
                }
            }
        }
    }
}

.request-access-block {
    &.section {
        padding-bottom: 0;
    }

    .layout-wrapper {
        display: flex;
        justify-content: space-between;
    }

    &__information {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__form {
        margin-bottom: 50px;
    }

    .form__row {
        &[data-input='buildingNameNumber'] {
            .form__label__text {
                margin-top: 10px;
            }
        }
    }

    &__title {
        font-size: 28px;
        font-weight: $font-weight--bold;
        margin-bottom: 14px;
    }

    &__description {
        color: $eggplant-purple;
        line-height: 1.3;
        margin-bottom: 40px;
        max-width: 250px;
    }

    &__image {
        .icon {
            @include size(300px 540px);
        }
    }
}

// Responsive styles
@include mq($until: 900px) {
    .request-access-block {
        &__image {
            .icon {
                height: 52vw;
                width: 100%;
            }
        }

        .form__label {
            max-width: 450px;
            width: 450px;
        }
    }
}

@include mq($until: tablet-l) {
    .request-access-block {
        .layout-wrapper {
            flex-wrap: wrap;
        }

        &__description {
            max-width: 100%;
        }

        .form__label {
            max-width: 516px;
            width: 516px;
        }

        &__image {
            display: none;
        }
    }
}

@include mq($until: tablet) {
    .request-access-block {
        &.section {
            padding-top: 30px;
        }

        &__form {
            width: 100%;
        }

        &__title {
            font-size: 22px;
        }

        &__description {
            font-size: 16px;
            line-height: 1.4;
            margin-bottom: 20px;
        }

        .form__button {
            @include button--size-mobile;
        }
    }
}

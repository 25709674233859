.layout-main {
    flex: 1 0 auto;
    margin: 0 auto;
    max-width: 1920px;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 1;

    &__two-column {
        margin-bottom: 72px;
        margin-top: $margin-size--l * (5 / 4);
        display: flex;
    }

    &__main {
        flex-grow: 1;
    }

    &__sidebar {
        margin-left: 60px;
        min-width: 400px;
        overflow: hidden;
        width: 400px;
    }

    &__breadcrumb {
        padding: $margin-size 0;
    }
}

.highlight {
    color: $brand-color;
}

.accent {
    color: $accent-color;
}

@include mq($until: desktop) {
    .layout-main {
        &__two-column {
            flex-wrap: wrap;
        }

        &__sidebar {
            margin-left: 0;
            min-width: 100%;
            width: 100%;

            .block {
                width: 100%;

                &__title {
                    width: 100%;

                    .light {
                        display: inline-block;
                    }
                }
            }
        }

        &__main {
            width: 100%;
        }
    }
}

@include mq($until: tablet-l) {
    .layout-main {
        &__two-column {
            margin-top: $margin-size;
        }
    }
}

select,
textarea {
    @include input;
    @include input--size-default;
}

input {
    &[type='text'],
    &[type='number'],
    &[type='email'],
    &[type='password'] {
        @include input;
        @include input--size-default;
    }
}

select {
    @include input--type-select;
}

textarea {
    @include input--type-textarea;
}

// Responsive styles
@include mq($until: tablet) {
    select,
    textarea {
        @include input--size($height: $widget-size--l, $font-size: 16px);
    }

    textarea {
        height: 10em;
    }

    input {
        &[type='text'],
        &[type='number'],
        &[type='email'],
        &[type='password'] {
            @include input--size($height: $widget-size--l, $font-size: 16px);
        }
    }
}

.cta-block {
    @include banner;

    &.project-cta-block {
        padding: 36px 0;

        .layout-wrapper {
            justify-content: flex-start;
        }

        .project-cta-block__title {
            font-size: 26px;
            font-weight: $font-weight--light;
            padding-left: 320px;
        }

        .grid-reference {
            font-weight: $font-weight--bold;
            white-space: nowrap;
        }

        .search-again {
            color: $geyser-blue;
            margin-top: 3px;
            font-size: 13px;

            &__link {
                color: $geyser-blue;
                text-decoration: underline;

                &:hover {
                    color: $white;
                }
            }
        }
    }

    &.results-cta-block {
        padding: 28px 0 57px 0;

        .name {
            color: $white;
            font-size: 12px;
            font-weight: $font-weight--light;
            margin-top: 6px;
        }
    }

    &.previous-searches-cta-block {
        .layout-wrapper {
            justify-content: flex-start;
        }
    }
}

@include mq($until: desktop) {
    .cta-block {
        &.results-cta-block {
            .grid-reference {
                display: block;
            }
        }
    }
}

@include mq($until: tablet-l) {
    .cta-block {
        &.project-cta-block {
            padding: 20px 0 52px 0;

            .project-cta-block__title {
                padding-left: 0;
                text-align: left;
            }
        }

        &.results-cta-block {
            .grid-reference {
                display: inline-block;
            }
        }
    }
}

@include mq($until: tablet) {
    .cta-block {
        &.access-cta-block {
            &__title {
                font-size: 19px;
                line-height: 1.5;
            }
        }

        &.project-cta-block {
            .project-cta-block__title {
                font-size: 22px;
            }
        }
    }
}

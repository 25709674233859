.how-it-works-block {
    background: url('/images/background/leaves.jpg') no-repeat center;
    background-size: cover;

    &.section {
        padding: 58px 0;
    }

    &__title {
        color: $white;
        font-size: 28px;
        line-height: 1.3;
        margin-bottom: 38px;
        text-align: center;
    }

    &__steps {
        display: flex;
        justify-content: space-between;

        .step {
            background: $white;
            border-radius: 10px;
            padding: 20px 24px 24px 60px;
            position: relative;
            width: (100% / 3);

            &__number {
                @include position(absolute, 16px null null 22px);

                color: $brand-color;
                font-size: 38px;
                font-weight: $font-weight--bold;
            }

            &__title {
                font-size: 20px;
                font-weight: $font-weight--bold;
                margin-bottom: 12px;

                a {
                    color: $brand-color--dark;
                    text-decoration: underline;
                }
            }

            &__description {
                color: $eggplant-purple;
                font-size: 15px;
                line-height: 1.35;
            }

            &:not(:last-child) {
                $arrow-size: 24px;
                $stepGap: 50px;

                margin-right: $stepGap;

                &::after {
                    @include size($arrow-size);

                    content: url("/images/how-it-works/arrow-right.svg");
                    display: block;
                    position: absolute;
                    right: -$stepGap + $arrow-size / 2;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }
}

// Responsive styles
@include mq($until: 900px) {
    .how-it-works-block {
        &__steps {
            flex-direction: column;

            .step {
                width: 100%;

                &:not(:last-child) {
                    $arrow-size: 24px;
                    $stepGap: 42px;

                    margin-bottom: $stepGap;
                    margin-right: 0;

                    &::after {
                        @include size($arrow-size);

                        content: url("/images/how-it-works/arrow-right.svg");
                        display: block;
                        position: absolute;
                        right: calc(50% - #{$arrow-size} / 2);
                        bottom: (-$stepGap + $arrow-size / 2) - 2;
                        top: unset;
                        transform: rotate(90deg);
                    }
                }
            }
        }
    }
}

@include mq($until: tablet) {
    .how-it-works-block {
        &.section {
            padding: 40px 0 64px 0;
        }

        &__title {
            font-size: 26px;
        }

        &__steps {
            .step {
                &__description {
                    font-size: 14px;
                }
            }
        }
    }
}

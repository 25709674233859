// CMS content styles
// ---------------------------------------------------------
@mixin cms-content {
    $list-padding: 40px;

    color: $eggplant-purple;
    font-size: 15px;
    line-height: 1.4;

    h2,
    h3 {
        color: $brand-color--dark;
        font-weight: $font-weight--bold;
    }

    h2,
    h3,
    p,
    ul,
    ol {
        margin-bottom: 20px;
    }

    h2 {
        font-size: 18px;
    }

    h3 {
        font-size: 16px;
        line-height: 1.1;
    }

    li {
        margin-bottom: 4px;
    }

    ol {
        counter-reset: li;
        list-style: none;
        padding-left: $list-padding;

        li {
            counter-increment: li;

            &::before {
                content: '.' counter(li);
                color: $brand-color;
                display: inline-block;
                width: 1.2em;
                margin-left: -1.5em;
                margin-right: 0.5em;
                text-align: right;
                direction: rtl
            }
        }
    }

    ul {
        padding-left: $list-padding;

        li {
            display: list-item;
        }
    }

    a {
        color: $action-color;
        font-weight: $font-weight--bold;
        text-decoration: underline;

        &:hover {
            color: $brand-color;
        }
    }
}


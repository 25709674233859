.example-results-block {
    &.section {
        padding: 0;
    }

    .layout-wrapper {
        display: flex;
        justify-content: space-between;
    }

    &__information {
        display: flex;
        flex-direction: column;
        padding: 50px 0;
    }

    &.project {
        .example-results-block {
            &__information {
                padding-right: 490px;
            }
        }
    }

    &__title {
        font-size: 24px;
        font-weight: $font-weight--bold;
        margin-bottom: 18px;
    }

    &__bullets {
        color: $eggplant-purple;
        font-size: 18px;
        margin-bottom: 22px;

        .bullet {
            padding-left: 40px;
            position: relative;
            margin-bottom: 16px;

            &::before {
                @include size(24px);
                @include position(absolute, 0 null null 0);

                content: url("/images/list/bullet-list.svg");
                display: inline-block;
            }
        }
    }

    &__button {
        align-self: flex-start;
    }

    .pricing-info {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &__pricing {
            margin-right: 20px;
        }

        &__rate {
            color: $brand-color;
            font-size: 16px;
        }

        &__price {
            font-size: 46px;
            font-weight: $font-weight--bold;
            line-height: 1.1;
        }

        &__vat {
            font-size: 17px;
            font-weight: $font-weight;
        }

        &__disclaimer {
            color: $brand-color;
            font-size: 14px;
            line-height: 1.4;
            margin-top: 16px;
            max-width: 200px;
        }
    }

    &__image {
        align-items: flex-end;
        display: flex;
        position: relative;

        .icon--glnp__example-results {
            @include size(500px 347px);
        }

        .icon--glnp__example-results-alt {
            @include position(absolute, null 0 -28px null);
            @include size(470px 439px);
        }
    }
}

// Responsive styles
@include mq($until: 850px) {
    .example-results-block {
        .layout-wrapper {
            flex-wrap: wrap;
        }
        &__information,
        &__image {
            width: 100%;

            .icon {
                margin-bottom: -28px;
                position: static;
            }
        }

        &.project {
            .example-results-block {
                &__information {
                    padding-bottom: 30px;
                    padding-right: 0;
                }

                &__pricing-info {
                    align-items: flex-end;
                    justify-content: flex-start;

                    .pricing-info__disclaimer {
                        margin-top: 6px;
                        max-width: 100%;
                    }
                }
            }
        }
    }
}

@include mq($until: tablet) {
    .example-results-block {
        &__information {
            padding-bottom: 0;
            padding-top: 40px;
        }

        &__title {
            font-size: 21px;
        }

        &__bullets {
            margin-bottom: 10px;

            .bullet {
                margin-bottom: 24px;
            }
        }

        &__image {
            .icon {
                height: 75vw;
            }
        }

        &__button {
            @include button--size-mobile;
        }
    }
}

.includes-hero {
    background: url('/images/background/homepage-banner.jpg') no-repeat center;
    background-size: cover;
}

.hero-block {
    border-top: 1px solid $jungle-mist-blue;
    padding: 0;

    .layout-wrapper {
        border-bottom: none;
        display: flex;
    }

    &__information-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 34px;
        margin-right: 30px;
    }

    &__text {
        margin-bottom: 40px;
    }

    &__title {
        color: $white;
        font-size: 34px;
        font-weight: $font-weight--bold;
        line-height: 40px;
    }

    &__subtitle {
        color: $jungle-mist-blue;
        font-size: 16px;
        line-height: 20px;
        margin-top: 20px;
    }

    &__button {
        margin-top: 26px;
        width: 182px;
    }

    &__eu {
        .icon {
            @include size(306px 68px);
        }
    }

    &__map {
        align-items: flex-end;
        display: flex;
        margin-right: 16px;

        img {
            max-height: 388px;
            max-width: 444px;
            width: 37vw;
        }
    }
}

// Responsive styles
@include mq($until: desktop) {
    .hero-block {
        &__map {
            margin-right: 0;
        }
    }
}

@include mq($until: 900px){
    .hero-block {
        &__information-wrapper {
            margin-right: 0;
        }

        &__map {
            display: none;
        }
    }
}

@include mq($until: tablet) {
    .hero-block {
        &__title {
            font-size: 25px;
            line-height: 30px;
        }

        &__subtitle {
            font-size: 15px;
        }
    }
}

@include mq($until: phone-l) {
    .hero-block {
        &__eu {
            text-align: center;

            .icon {
                @include size(282px 63px);
            }
        }
    }
}

.error-block {
    border-bottom: 4px solid $mystic-blue;
    padding: 20px 0;
    text-align: center;

    &.block {
        padding: 30px 0;
    }

    .layout-wrapper {
        padding: 72px 0;
        position: relative;
    }

    &__wrapper {
        margin: 0 auto;
        max-width: 500px;
    }

    &__status-code {
        font-size: 54px;
        font-weight: $font-weight--bold;
        margin-bottom: 18px;
    }

    &__status-text {
        font-size: 22px;
        font-weight: $font-weight--bold;
        margin-bottom: 12px;
    }

    &__information {
        color: $eggplant-purple;
        font-size: 14px;
        margin-bottom: 36px;
    }

    &__back-button {
        display: flex;
        justify-content: center;

        .button {
            align-self: flex-start;
        }
    }

    .icon--glnp__leaves-ghost-alt {
        @include position(absolute, 0 0 0 null);
        @include size(246px 386px);

        z-index: -1;
    }
}

// Responsive styles
@include mq($until: tablet-l) {
    .error-block {
        &.block {
            padding: 50px 0 80px 0;
        }

        .layout-wrapper {
            padding: 0;
        }

        .icon--glnp__leaves-ghost-alt {
            display: none;
        }
    }
}

@import '../vendor/mfp';

// Default animation.
// ---------------------------------------------------------

.mfp-bg {
    opacity: 0;

    transition-duration: .3s;
    transition-property: opacity;

    &.mfp-ready {
        opacity: $mfp-overlay-opacity;
    }

    &.mfp-removing {
        opacity: 0;
    }
}

.mfp-wrap {
    .mfp-content {
        opacity: 0;
        transform: scale(1.05);

        transition-duration: .3s;
        transition-property: opacity, transform;
    }

    &.mfp-ready {
        .mfp-content {
            opacity: 1;
            transform: scale(1);
        }
    }

    &.mfp-removing {
        &.mfp-content {
            opacity: 0;
            pointer-events: none;
            transform: scale(.95);
        }
    }
}

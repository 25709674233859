.funders-block {
    padding: 35px 0;

    .layout-wrapper {
        display: flex;
    }

    &__title {
        font-size: 24px;
        font-weight: $font-weight--bold;
        margin-bottom: 12px;
    }

    &__description {
        color: $eggplant-purple;
        font-size: 15px;
        line-height: 1.3;
        margin-right: 20px;
    }

    &__logos {
        display: flex;

        .icon {
            @include size(160px 105px);
        }
    }

    &__link {
        @include size(159px 104px);

        align-items: center;
        background: $white;
        border: 1px solid $geyser-blue;
        display: flex;
        min-width: 159px;
    }

    &__image {
        height: auto;
        width: 100%;
    }

    &__link + &__link {
        margin-left: 20px;
    }
}

// Responsive styles
@include mq($until: desktop) {
    .funders-block {
        .layout-wrapper {
            flex-wrap: wrap;
            justify-content: center;
        }

        &__information {
            text-align: center;
            margin-bottom: 20px;
        }
    }
}

@include mq($until: desktop) {
    .funders-block {
        .layout-wrapper {
            justify-content: flex-start;
        }

        &__information {
            text-align: left;
        }

        &__link + &__link {
            margin-left: 12px;
        }
    }
}

@include mq($until: tablet-l) {
    .funders-block {
        .layout-wrapper {
            border-right-width: 0;
        }

        &__information {
            border-right: 6vw solid transparent;
        }

        &__description {
            margin-right: 0;
        }

        &__logos-wrapper {
            overflow: auto;
            width: 100%;
        }

        &__link:last-child {
            border-right: 6vw solid transparent;
        }
    }
}

@include mq($until: tablet) {
    .funders-block {
        padding: 32px 0;

        &__description {
            font-size: 14px;
        }
    }
}

// Viewport declaration.
// ---------------------------------------------------------

@-ms-viewport {
    width: device-width;
}

@viewport {
    width: device-width;
}

// Global overflow hidden
// ---------------------------------------------------------
html {
}

// Global typography.
// ---------------------------------------------------------
body {
    color: $text-color;
    font-family: $font-family;
    font-size: $font-size;
    font-weight: $font-weight;
    line-height: $line-height;
    min-width: 320px;
    overflow-x: hidden;
}

// Global `border-box` sizing.
// ---------------------------------------------------------
* {
    box-sizing: border-box;

    &::before,
    &::after {
        box-sizing: border-box;
    }

    &:focus {
        outline: 0 none;
    }
}

// Destyle deprecated elements.
// ---------------------------------------------------------

b,
i,
u,
s,
center,
big,
small {
    display: inline;
    font-style: inherit;
    font-weight: inherit;
    text-align: inherit;
    text-decoration: inherit;
}

// Text-selection style.
// ---------------------------------------------------------

::selection {
    background: rgba($selection-color, .9);
    color: contrasting-color($selection-color);
    text-shadow: none;
}

// Reset anchor styles.
// ---------------------------------------------------------

a {
    color: $link-color;
    cursor: pointer;
    text-decoration: inherit;
    transition-property: background-color, color;
    transition-duration: $hover-duration;
    transition-timing-function: $hover-timing-function;

    &:focus {
        outline: 0 none;
        text-decoration: none;
    }

    &:active,
    &:hover {
        color: $link-color--active;
        text-decoration: none;
    }
}

// Reset heading styles.
// ---------------------------------------------------------

h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-size: inherit;
    font-weight: inherit;
    margin: 0;
}

// Reset list styling.
// ---------------------------------------------------------

ul,
ol,
li {
    display: block;

    &:focus {
        outline: 0 none;
    }
}

ul,
ol {
    margin: 0;
    padding: 0;
}

// Reset <address> styles.
// ---------------------------------------------------------

address {
    display: block;
    font-style: inherit;
}

// Remove baseline gap from inline-block elements.
// ---------------------------------------------------------

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: top;
}

// Allow only vertical resizing of textareas.
// ---------------------------------------------------------

textarea {
    resize: vertical;
}

// Touch interaction.
// ---------------------------------------------------------

a,
label,
button {
    touch-action: manipulation;
}

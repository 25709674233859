.customer-invoice {
    overflow: hidden;

    .container {
        font-size: 8.5pt;
        height: 297mm;
        overflow: hidden;
        padding: 40px;
        position: relative;
        width: 210mm;
    }

    .bold {
        font-weight: $font-weight--bold;
    }

    .label {
        display: inline-block;
        margin-right: 6px;
        min-width: 96px;
        text-align: right;
    }

    .header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 70px;
    }

    .logo {
        width: 180px;
    }

    .title {
        color: $flamingo-red;
        font-size: 20pt;
        font-weight: $font-weight--bold;
    }

    .contact-name {
        margin-top: 14px;
    }

    .row {
        display: flex;
        justify-content: space-between;

        .address-and-invoice-details {
            display: flex;
            line-height: 1.5;
        }

        .invoice-details {
            margin-left: 20px;
        }
    }

    .invoice-details {
        .invoice-date,
        .invoice-due {
            margin-bottom: 4px;
        }
    }

    .invoice-paid {
        align-self: flex-start;
        border: 2px solid $flamingo-red;
        font-size: 10pt;
        padding: 14px;

        &__title {
            font-weight: $font-weight--bold;

            .paid {
                color: $flamingo-red;
                font-weight: $font-weight--bold;
                text-transform: uppercase;
            }
        }

        &__thanks {
            color: $waterloo-blue;
            font-size: 7pt;
            margin-top: 2px;
        }
    }

    .invoice-table {
        border-collapse: collapse;
        font-size: 8pt;
        margin-top: 36px;
        width: 100%;

        .table-headers {
            background: $thunder-gray;
            color: $white;
        }

        td,
        th {
            padding: 8px;
            text-align: left;
        }

        th {
            font-weight: $font-weight;
        }

        .quantity {
            text-align: center;
        }

        .invoice-item {
            border-bottom: 1px solid $mischka-gray;

            td {
                color: $mirage-blue;
                padding: 14px 8px;
            }

            &__grid-reference {
                color: $waterloo-blue;
                font-size: 7.5pt;
                margin-top: 2px;
            }
        }

        .total-highlight {
            background: $apricot-peach;
        }

        .total-net,
        .total-vat,
        .total-invoice {
            td {
                padding: 12px 8px;
            }

            .total-label {
                text-align: right;
            }
        }

        .total-net {
            .total-label,
            .total-value {
                border-bottom: 1px solid $mischka-gray;
            }
        }

        .service {
            min-width: 240px;
        }

        .search-date {
            min-width: 106px;
        }

        .quantity {
            min-width: 60px;
        }

        .rate {
            min-width: 104px;
        }

        .vat {
            min-width: 70px;
        }
    }

    .footer {
        @include position(absolute, null 0 0 0);

        .payment-details {
            line-height: 1.6;
            padding: 0 40px 60px 40px;

            p:not(:last-child) {
                margin-bottom: 4px;
            }
        }

        .icon {
            @include size(210mm 35mm);

            margin-bottom: -2px;
        }

        &__content {
            background: $thunder-gray;
            color: $silver-chalice-gray;
            line-height: 1.6;
            padding: 26px 40px;
            width: 100%;

            p {
                margin-bottom: 4px;
            }

            .white {
                color: $white;
                font-weight: $font-weight--bold;
            }

            .orange {
                color: $flamingo-red;
                font-weight: $font-weight--bold;
            }
        }
    }
}

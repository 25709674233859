.search-form-block {
    &__form {
        padding-top: 45px;
    }

    .form {
        @include form--full-width-labels;

        padding: 0 0 44px 0;
        text-align: center;

        &__title {
            font-size: 34px;
            font-weight: $font-weight--bold;
            text-align: center;
        }

        &__row {
            justify-content: center;
        }

        &__label {
            color: $brand-color--dark;
        }

        &__label__text {
            margin-bottom: 10px;
            margin-top: 6px;
        }

        &__input {
            border-radius: 26px;
            font-size: 26px;
            margin-bottom: 10px;
            text-align: center;
        }

        &__button {
            margin: 12px auto 0 auto;
        }

        &__error,
        &__hint {
            span {
                font-weight: $font-weight--bold;
            }
        }
    }

    .icon--glnp__leaves-ghost {
        @include position(absolute, null -14px -80px null);
        @include size(256px 460px);

        opacity: .5;
        z-index: -1;
    }
}

// Reponsive styles
@include mq($until: desktop) {
    .search-form-block {
        .icon--glnp__leaves-ghost {
            display: none;
        }
    }
}

@include mq($until: tablet-l) {
    .search-form-block {
        &__form {
            padding-top: 0;
        }

        .form {
            padding: 10px 0;
        }
    }
}

@include mq($until: tablet) {
    .search-form-block {
        .form {
            padding: 0;

            &__title {
                font-size: 27px;
                margin-bottom: 16px;
            }

            &__row {
                &[data-input="searchRadius"] {
                    .form__label__text {
                        margin-bottom: 30px;
                    }
                }
            }

            &__label__text {
                margin: 6px auto 10px auto;
                max-width: 160px;
                text-align: center;
            }
        }
    }
}

.project-loading-block {
    border-bottom: 4px solid $mystic-blue;
    position: relative;

    &.section {
        padding: 138px 0;
    }

    .layout-wrapper {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    &__wrapper {
        text-align: center;
    }

    &__icon {
        .icon {
            @include size(60px);
            animation: rotation 1.5s infinite linear;
        }
    }

    &__title {
        font-size: 24px;
        font-weight: $font-weight--bold;
        line-height: 1.3;
        margin: 24px auto 0 auto;
    }

    &__description {
        color: $mountbatten-pink;
        font-size: 14px;
        line-height: 1.3;
        margin: 14px auto 0 auto;
        max-width: 295px;
    }

    .icon--glnp__leaves-ghost-alt {
        @include position(absolute, null -64px -176px null);
        @include size(256px 460px);

        opacity: .5;
        z-index: -1;
    }

    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(359deg);
        }
    }
}

// Responsive styles
@include mq($until: tablet-l) {
    .project-loading-block {
        &.section {
            padding: 100px 0;
        }

        .icon--glnp__leaves-ghost-alt {
            bottom: -200px;
        }
    }
}

@include mq($until: tablet) {
    .project-loading-block {
        &.section {
            padding: 90px 0;
        }

        &__title {
            font-size: 21px;
            max-width: 220px;
        }

        &__description {
            margin-top: 18px;
            max-width: 250px;
        }

        .icon--glnp__leaves-ghost-alt {
            bottom: -200px;
        }
    }
}

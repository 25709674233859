.project-summary-block {
    min-height: 240px;
    padding: 30px 0 60px 0;
    position: relative;

    &__map {
        @include position(absolute, -123px null null 0);

        padding-bottom: 30px;

        .change-radius {
            @include position(absolute, null null 0 0);

            background: $white;
            font-size: 12px;
            padding: 8px 14px 8px 10px;

            &__radius {
                font-weight: $font-weight--bold;
            }

            &__link {
                color: $eggplant-purple;
                text-decoration: underline;

                &:hover {
                    color: $brand-color;
                }
            }
        }
    }

    &__information {
        @include collapsible($iconSize: 14px, $breakpoint: 'tablet');
    }

    &__summary {
        padding-left: 320px;

        .pricing-info {
            align-items: center;
            display: flex;
            flex-wrap: wrap;

            &__pricing {
                background: $mystic-blue;
                border-radius: 8px;
                margin: 10px 20px 10px 0;
                padding: 20px;
            }

            &__title {
                color: $brand-color;
                font-size: 15px;
            }

            &__price {
                font-size: 36px;
                font-weight: $font-weight--bold;
                line-height: 1.1;
            }

            &__vat {
                font-size: 14px;
                font-weight: $font-weight;

                & > p {
                    display: inline-block;
                }
            }

            &__disclaimer {
                color: $eggplant-purple;
                font-size: 14px;
                line-height: 1.4;
                max-width: 200px;

                & > p {
                    margin-bottom: 6px;
                    max-width: 120px;
                }

                .direct-invoice {
                    font-weight: $font-weight--bold;
                }
            }
        }
    }

    &__title {
        font-size: 18px;
        font-weight: $font-weight--bold;
        margin-bottom: 10px;
    }

    &__subtitle {
        color: $eggplant-purple;
        font-size: 14px;
        margin-bottom: 10px;
    }

    &__link {
        align-items: center;
        display: flex;
        font-size: 14px;
        font-weight: $font-weight--bold;
        margin-bottom: 14px;

        .icon {
            @include size(12px);

            margin-left: 4px;
        }
    }

    &__form {
        padding-top: 6px;

        .form {
            @include form--full-width-labels;

            &__row[data-input='optionalReference'] {
                .form__input-wrapper {
                    max-width: 452px;
                }

                .form__label__text {
                    margin-top: 10px;

                    .optional {
                        display: inline;
                        margin-left: 6px;
                    }

                    br {
                        display: none;
                    }
                }
            }

            &__button {
                margin-left: 0;
                margin-top: 20px;
            }
        }
    }

    &__outside-area,
    &__part-area {
        margin-top: 14px;
    }

    &__outside-area {
        padding-bottom: 50px;
    }

    .info-box {
        background: $mystic-blue;
        border-radius: 5px;
        color: $horizon-blue;
        font-size: 14px;
        line-height: 1.4;
        margin-bottom: 26px;
        max-width: 540px;
        padding: 20px 20px 20px 60px;
        position: relative;

        .icon {
            @include position(absolute, 16px null null 20px);
            @include size(24px);
        }
    }

    .icon--glnp__leaves-ghost-alt {
        @include position(absolute, null -54px -80px null);
        @include size(256px 460px);

        opacity: .5;
        z-index: -1;
    }
}

// Repsonsive styles
@include mq($until: desktop) {
    .project-summary-block {
        .icon--glnp__leaves-ghost-alt {
            display: none;
        }
    }
}

@include mq($until: tablet-l) {
    .project-summary-block {
        padding-top: 300px;

        &__summary {
            padding-left: 0;
        }

        &__map {
            top: -334px;
        }

        &__outside-area,
        &__part-area {
            padding-bottom: 20px;
        }
    }
}

@include mq($until: tablet) {
    .project-summary-block {
        padding-top: 290px;

        &__map {
            top: -324px;

            .change-radius {
                padding: 11px 16px 13px 0;
            }
        }

        &__pricing-info {
            .pricing-info {
                &__pricing {
                    margin: 16px 0;
                    width: 100%;
                }

                &__disclaimer {
                    margin-bottom: 14px;
                    max-width: 100%;
                    width: 100%;

                    & > p {
                        max-width: 100%;
                    }
                }
            }
        }

        &__form {
            .form {
                &__row[data-input='optionalReference'] {
                    .form__input-wrapper {
                        width: 100%;
                    }
                }

                &__row--buttons {
                    div {
                        width: 100%;
                    }
                }

                &__button {
                    @include button--size-mobile;
                }
            }
        }

        &__outside-area {
            .button {
                border-radius: 34px;
                line-height: 1.4;
                padding: 33px 20px;
                white-space: normal;
            }
        }
    }
}

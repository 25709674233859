$nav-height: 100px;

// Header container.
// ---------------------------------------------------------
.layout-header {
    background: $brand-color;
    flex: 0 0 auto;
    min-height: $nav-height;
    width: 100%;
    z-index: 2;

    // >= tablet
    @include mq($from: tablet) {
        .layout-wrapper {
            border-bottom: $margin-size--s solid transparent;
            border-top: $margin-size--s solid transparent;
        }
    }

    // >= tablet-l
    @include mq($from: tablet-l) {
        &__mobile-logo {
            display: none;
        }
    }

    // < tablet-l
    @include mq($until: tablet-l) {

        :root &__nav {
            @include position(absolute, 0 null null 100%);
            @include size(0 auto);

            .layout-wrapper {
                border: 0 none !important;
            }
        }

        &__mobile-logo {
            display: inline-block;
            height: $nav-height;
            margin-left: 6vw;

            .icon {
                @include size(160px 54px);

                margin-top: (50px - 27px);
            }
        }

    }
}

// Header navigation container.
// ---------------------------------------------------------

.layout-header-nav {
    font-weight: $font-weight--light;
    position: relative;

    .layout-wrapper {
        border-bottom-width: 0;
        border-top-width: 0;
        height: 100%;
    }

    &__inner {
        min-height: $nav-height;
    }

    &__title {
        @include visuallyhidden;
    }

    // >= tablet:
    @include mq($from: tablet-l) {

        &__inner {
            align-items: center;
            display: flex;
            justify-content: center;
        }

        &__menu-btn {
            @include size(26px);
            display: none;
        }
    }

    @include mq($from: desktop, $until: desktop-l) {
        &__inner {
            justify-content: space-between;
        }
    }

    // < tablet:
    @include mq($until: tablet-l) {
        background: $black;

        &__inner {
            background: $white;
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        &__menu-btn {
            $btn-size: 48px;
            $icon-size: 26px;

            @include position(absolute, $icon-size null null (0 - $btn-size));
            @include size($btn-size);

            .icon {
                @include size($icon-size);
                color: $white;
                margin: ($btn-size - $icon-size) / 2;
            }

            .show-ocm & {
                margin-top: 0;
            }

            &:hover {
                color: $brand-color--dark;
            }
        }
    }
}

// Navigation menu.
// ---------------------------------------------------------
.layout-header-menu {
    @include mq($from: tablet-l) {
        align-items: center;
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;

        &__item {
            align-items: center;
            display: flex;
            padding: 0 10px;
            position: relative;

            &.mobile-only {
                display: none;
            }

            .layout-header-menu__link {
                .link-text {
                    padding: 2px 4px;
                }
            }
        }

        &__link {
            align-items: center;
            color: $white;
            display: flex;
            font-size: 15px;
            height: 100%;
            justify-content: center;
            padding: 6px 4px 4px;
            text-align: center;

            .link-text {
                display: block;
                text-align: left;
                width: 100%;
            }

            &:active,
            &:hover {
                color: $jungle-mist-blue
            }

            &:focus {
                text-decoration: none;
            }

            &.mobile {
                display: none;
            }
        }

        &__logo {
            display: block;

            .icon {
                @include size(160px 54px);
            }
        }

        &__close {
            display: none;
        }
    }

    @include mq($from: desktop, $until: desktop-l) {
        width: auto;

        &__logo {
            //flex-grow: 1;
            margin: 0;
        }
    }

    @include mq($from: tablet-l, $until: desktop) {
        margin-bottom: 20px;
        margin-top: 60px;
        justify-content: center;
        width: auto;

        &__logo {
            height: auto;
            margin: 0;
            position: absolute;
            top: $margin-size--s * (1 / 2);

            .icon {
                height: 46px;
            }
        }

        &__item {
            height: 70%;
        }

        &__link {
            white-space: nowrap;
        }
    }

    @include mq($until: tablet-l) {
        display: block;
        overflow: hidden;

        &__submenu {
            a {
                padding-left: 40px;
            }

            li {
                background: darken($brand-color--dark, 5%);
            }
        }

        &__link {
            align-items: center;
            border-bottom: 1px solid $mystic-blue;
            color: $brand-color--dark;
            display: flex;
            font-size: 18px;
            font-weight: $font-weight--bold;
            height: 54px;
            justify-content: space-between;
            line-height: 48px;
            padding: 0 22px;

            &:hover {
                color: $brand-color;
            }
        }

        &__logo {
            display: none;
        }

        &__item {
            .button--ghost-white {
                @include button--skin-ghost--red;

                margin: 40px 22px;
            }
        }

        &__close {
            align-items: center;
            background: rgba($brand-color, .1);
            color: $nepal-blue;
            display: flex;
            font-size: 14px;
            justify-content: flex-end;
            padding: 13px 22px;
            text-transform: uppercase;

            .icon {
                @include size(12px);

                margin-right: 6px;
            }
        }
    }
}

// Off-canvas menu transition.
// ---------------------------------------------------------
@keyframes pseudoFade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@include mq($until: tablet-l) {

    :root .layout-header-nav {
        height: 100%;
        width: 272px;
        z-index: 999999;
    }

    .mobile-nav {
        &__outer-wrapper {
            overflow: hidden;
        }

        &__inner-wrapper {
            transform: translateX(0);
            transition: transform .2s $easeInOutQuad;

            &.show-ocm {
                transform: translateX(-272px);

                &::after {
                    animation: pseudoFade .4s ease;
                    background: rgba($mineshaft-gray, .75);
                    content: "";
                    display: block;
                    position: fixed;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    z-index: 10;
                }
            }
        }
    }
}

// Alternate header layout for search area
// ---------------------------------------------------------
.layout-header-alt {
    background: $white;
    border-bottom: 2px solid $mystic-blue;
    color: $brand-color--dark;

    .layout-header-nav {
        font-weight: $font-weight;

        &__menu-btn {
            .icon {
                color: $old-lavender-purple;
            }

            .show-ocm & {
                margin-top: 0;

                .icon {
                    color: rgba($white, .6);
                }
            }

            &:hover {
                color: $brand-color--dark;
            }
        }
    }

    .layout-header-menu {
        &__logo {
            margin-right: 28px;
        }

        &__link {
            color: $brand-color--dark;
            font-weight: $font-weight--bold;
        }

        &__item {
            &.mobile-logout {
                display: none;
            }
        }

        .icon--menu {
            @include size(24px);

            color: $mountbatten-pink;
            margin-right: 4px;
            min-height: 24px;
            min-width: 24px;
        }

        .icon--menu__menu-help {
            @include size(20px 24px);

            margin-right: 0;
            min-height: 24px;
            min-width: 20px;
        }
    }

    &__user-greeting {
        font-size: 14px;
        line-height: 1.5;

        .user {
            font-weight: $font-weight--bold;
        }
    }

    &__logout {
        color: $old-lavender-purple;
        font-size: 13px;

        .logout__link {
            color: $old-lavender-purple;
            font-weight: $font-weight--bold;
        }
    }
}


// Responsive styles for alternate header layout
// ---------------------------------------------------------
@include mq($until: desktop) {
    .layout-header-alt {
        .layout-header-nav {
            &__inner {
                justify-content: space-between;
            }

            &__menu {
                flex-grow: 0;
            }
        }

        &__user-wrapper {
            margin-bottom: 20px;
            margin-top: 60px;
        }

        .layout-header-menu {
            &__logo {
                left: 50%;
                transform: translateX(-50%);
            }

            &__item {
                padding: 0 6px;
            }
        }
    }
}

@include mq($from: tablet-l) {
    .layout-header-alt {
        .layout-header-menu {
            &__link {
                &.active {
                    color: $brand-color;

                    .icon--menu {
                        color: $nepal-blue;
                    }
                }
            }
        }
    }
}

@include mq($until: tablet-l) {
    .layout-header-alt {
        &__user-wrapper {
            margin: 0;
        }

        &__user-greeting {
            border-bottom: 1px solid $mystic-blue;
            padding: 14px 22px;
        }

        &__logout {
            display: none;
        }

        .layout-header-nav {
            &__inner {
                justify-content: flex-start;
            }

            &__menu {
                flex-grow: 1;
            }
        }

        .layout-header-menu {
            &__item {
                padding: 0;

                &.mobile-logout {
                    display: block;
                }
            }

            &__link {
                justify-content: flex-start;

                .icon--menu {
                    min-width: 24px;
                    margin-right: 10px;
                }
            }
        }
    }
}

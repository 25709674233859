// Banner styles
// ---------------------------------------------------------
@mixin banner {
    background: linear-gradient(to right, $brand-color, $horizon-blue);
    padding: 35px 0;

    .layout-wrapper {
        align-items: center;
        display: flex;
        justify-content: center;
        min-height: 40px;
    }

    &__title {
        color: $white;
        font-size: 22px;
        line-height: 1.4;

        a {
            color: $white;

            &:hover {
                color: $jungle-mist-blue;
            }
        }
    }

    &__button {
        flex-shrink: 0;
        padding: 14px 25px !important;
        margin-left: 30px;
    }

    // Responsive styles
    @include mq($until: tablet-l) {
        .layout-wrapper {
            flex-wrap: wrap;
        }

        &__title {
            margin-right: 0;
            text-align: center;
            width: 100%;
        }

        &__button {
            margin-left: 0;
            margin-top: 20px;
        }
    }

    @include mq($until: tablet) {
        &__button {
            @include button--size-mobile;

            margin-top: 25px;
        }
    }
}


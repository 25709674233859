.why-choose-us-block {
    &__title {
        font-size: 24px;
        line-height: 1.3;
        padding: 0 192px;
        text-align: center;
    }

    &__benefits {
        display: flex;
        flex-wrap: wrap;
        font-size: 18px;
        justify-content: space-between;

        .benefit {
            display: flex;
            margin-top: 40px;
            width: 270px;

            &__image {
                @include size(34px);

                margin-right: 20px;
                margin-top: 4px;
            }
        }
    }
}

// Responsive styles
@include mq($until: desktop) {
    .why-choose-us-block {
        &__title {
            padding: 0 120px;
        }
    }
}

@include mq($until: 920px) {
    .why-choose-us-block {
        &__title {
            padding: 0;
        }

        &__benefits {
            .benefit {
                width: calc((100% / 2) - 20px);

                &:nth-child(odd) {
                    margin-right: 20px;
                }
            }
        }
    }
}

@include mq($until: tablet) {
    .why-choose-us-block {
        &__title {
            font-size: 22px;
            line-height: 1.4;
        }

        &__benefits {
            margin-bottom: 20px;

            .benefit {
                margin-right: 0;
                width: 100%;
            }
        }
    }
}

// Component variables.
// ---------------------------------------------------------
$negative-color: crimson !default;
$focus-color: lightblue !default;

$error-icon-url: "/icons/exclamation.svg" !default;
$error-icon-size: 16px !default;
$error-icon-gap: $error-icon-size * 1.5 !default;
$error-border-color: $can-can-red !default;
$error-message-color: $negative-color !default;

$widget-height: 44px !default;

$input-border-width: 1px !default;
$input-border-radius: 3px !default;

$form-hint-error-size: 38px !default;

// Form structure.
// ---------------------------------------------------------

@mixin form-validation() {
    &__row {
        position: relative;

        &.required {
            .form__input-wrapper {
                &::after {
                    @include position(absolute, 0 (-$error-icon-gap / 2) null null);

                    content: '*';
                    color: $negative-color;
                    font-size: ceil($widget-height / 3);
                }
            }
        }

        &.error {
            .form__input-wrapper {
                &::after {
                    @include position(absolute, $widget-height (-$error-icon-gap) null null);
                    @include size($error-icon-size);

                    content: url(#{$error-icon-url});
                    transform: translateY(-($widget-height / 2 + $error-icon-size / 2));
                }
            }
        }
    }

    .form__input.dirty:invalid {
        border-color: $error-border-color;

        & ~ .form__messages {
            .form__error {
                display: block;
            }

            .form__hint {
                display: none;
            }
        }
    }

    .form__input:focus,
    .form__input:focus:invalid {
        border-color: $focus-color;

        & ~ .form__messages {
            .form__hint {
                display: block;
            }

            .form__error {
                display: none;
            }
        }
    }

    &__input-wrapper {
        position: relative;
        width: 100%;
    }

    &__messages {
        width: 100%;
    }

    &__error {
        color: $error-message-color;
        display: none;
    }

    &__error,
    &__hint {
        display: none;
        height: auto;
        line-height: 1.5;
        text-align: left;
    }

    &__toggle-password {
        font-size: 12px;
        margin-top: 6px;
        text-decoration: underline;

        &:hover {
            cursor: pointer;
        }
    }
}

.range-widget {
	$range-size: 28px;
	$range-overflow: $range-size / 2;
	$side-label-size: 40px;
	$side-margin: ($side-label-size + $margin-size + $range-overflow);
	$marker-height: 30px;
	$marker-width: 48px;

	&__inner {
		line-height: $line-height;
		margin: 0 $side-margin;
		position: relative;
	}

	&__group {
		display: block;
		padding-bottom: $marker-height + 3px;
		position: relative;
	}

	&__tick-wrapper {
		@include position(absolute, 10px -5px null -5px);

		display: flex;
		justify-content: space-between;
	}

	&__tick {
		@include size(8px);

		background: $geyser-blue;
		border-radius: 50px;
		display: block;

		&:first-child,
		&:last-child {
			visibility: hidden;
		}
	}

	:root &__range {
		font-size: $range-size;
		margin: 0 (0 - $range-overflow);
		position: relative;
		width: calc(100% + #{$range-overflow * 2});
		z-index: 1;
	}

	&__min,
	&__max {
		@include position(absolute, 0 null null null);
		@include size($side-label-size $range-size);
		color: $eggplant-purple;
		font-size: 15px;
		line-height: $range-size;
	}

	&__min {
		left: (12px - $side-margin);
		text-align: right;
	}

	&__max {
		right: (12px - $side-margin);
		text-align: left;
	}

	&__marker {
		@include position(absolute, null);
		@include size($marker-width $marker-height);

		bottom: -$marker-height;
		margin-bottom: $marker-height + 3px;
		margin-left: ($marker-width / -2);
		margin-right: ($marker-width / -2);
		opacity: 0;
		pointer-events: none;

		&::after {
			@include position(absolute, 0);
			@include size($marker-width $marker-height);

			color: $brand-color--dark;
			content: "" attr(data-value)"km";
			font-size: 15px;
			padding-top: ($marker-height * 0.1);
			text-align: center;
		}

		&.is-active {
			opacity: 1;
		}

		@include enable-transitions() {
			transition: left 0.3s ease, opacity 0.3s ease-in;

			&.is-active {
				transition: left 0.3s ease, opacity 0.1s ease-out;
			}

			&.no-smoothing {
				transition: opacity 0.3s ease-in;
			}

			&.is-active.no-smoothing {
				transition: opacity 0.1s ease-out;
			}
		}
	}

	@include mq($until: phone-l) {
		&__min,
		&__max {
			font-size: 13px;
		}
	}
}

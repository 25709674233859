// Add transition styles to an element.
// ---------------------------------------------------------

@mixin enable-transitions {
    @content;

    .no-animate & {
        transition: none !important;
    }
}

.help-block-header {
    @include banner;

    padding: 27px 0;

    .layout-wrapper {
        justify-content: flex-start;
    }

    &__title {
        font-size: 26px;
    }
}

.help-block {
    border-bottom: 4px solid $mystic-blue;

    .layout-wrapper {
        position: relative;

        .icon--glnp__leaves-ghost {
            @include size(290px 520px);
            @include position(absolute, null 0 -146px null);

            opacity: .5;
            z-index: -1;
        }
    }

    &__wrapper {
        display: flex;
    }

    &__navigation {
        align-self: flex-start;
        margin-right: 36px;
        min-width: 200px;
        padding: 20px;
    }

    .quick-nav {
        border-radius: 12px;
        border: 1px solid $mystic-blue;
        box-shadow: 3px 0 16px $mystic-blue;

        &__title {
            color: $brand-color--dark;
            font-size: 16px;
            font-weight: $font-weight--bold;
            margin-bottom: 10px;
        }

        &__link {
            color: $eggplant-purple;
            display: block;
            font-size: 14px;
            padding: 9px 0;
        }
    }

    .quick-nav__item + .quick-nav__item {
        border-top: 1px solid $mystic-blue;
    }

    &__content {
        @include cms-content;
    }

    .help-list {
        @include collapsible(14px, 'tablet');

        &__item {
            padding-bottom: 20px;
        }

        .how-to {
            .help-list__steps {
                align-items: center;
                display: flex;
                justify-content: space-between;

                .step {
                    width: calc(100% / 3);

                    &__image {
                        width: 100%;
                    }

                    &__content {
                        margin-top: 20px;
                        position: relative;
                        padding-left: 34px;
                    }

                    &__number {
                        @include position(absolute, 2px null null 0);

                        color: $brand-color;
                        font-size: 38px;
                        font-weight: $font-weight--bold;
                        line-height: 1;
                    }

                    &__title {
                        color: $brand-color--dark;
                        font-size: 20px;
                        font-weight: $font-weight--bold;
                        margin-bottom: 4px;
                    }
                }

                .step + .step {
                    margin-left: 20px;
                }
            }
        }
    }
}

// Responsive styles
@include mq($from: tablet, $until: desktop) {
    .help-block {
        .help-list {
            .how-to {
                .help-list__steps {
                    flex-wrap: wrap;

                    .step {
                        display: flex;
                        width: 100%;

                        &__image {
                            height: 176px;
                            margin-right: 20px;
                            width: 220px;
                        }

                        &__content {
                            margin-top: 10px;
                        }
                    }

                    .step + .step {
                        margin-left: 0;
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}

@include mq($until: tablet-l) {
    .help-block-header {
        &__title {
            text-align: left;
        }
    }

    .help-block {
        &__navigation {
            display: none;
        }

        &__content {
            width: 100%;
        }
    }
}

@include mq($until: tablet) {
    .help-block-header {
        &__title {
            font-size: 22px;
        }
    }

    .help-block {
        padding: 10px 0 30px 0;

        .help-list {
            &__title {
                margin-bottom: 0;
                padding: 12px 26px 12px 0;

                .icon {
                    color: $action-color;
                }
            }

            &__item {
                padding-bottom: 0;
            }

            .help-list__item:last-child {
                border-bottom: 0;
            }

            .how-to {
                .help-list__steps {
                    flex-wrap: wrap;

                    .step {
                        width: 100%;

                        &__content {
                            margin-top: 10px;
                        }
                    }

                    .step + .step {
                        margin-left: 0;
                        margin-top: 20px;
                    }
                }
            }
        }

        .icon--glnp__leaves-ghost {
            display: none;
        }
    }
}

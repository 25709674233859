.dialog {
    background: $white;
    max-width: 100%;
    width: 980px;

    &__head {
        padding-bottom: 12px;
        padding-right: 72px;
    }

    &__title {
        display: inline-block;
        font-size: $font-size--l * (3 / 2);
        font-weight: $font-weight--light;
        line-height: 46px;
        outline: 0 none !important;

        .fw-normal {
            font-weight: $font-weight;
        }
    }

    &__body {
        display: flex;
    }

    &__foot {
        display: flex;
    }

    &__foot-group {
        .button:not(:first-child) {
            margin-left: $margin-size--s;
        }
    }

    &__foot-group + &__foot-group {
        margin-left: auto;
    }
}

.modal .dialog {
    display: flex;
    flex-direction: column;
    max-height: $modal-max-height;
    max-width: $modal-max-width;

    &__head,
    &__foot {
        flex: 0 0 auto;
    }

    &__body {
        flex: 1 1 auto;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
}

.dialog-layout {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    padding: $margin-size * (5 / 3);
}

.dialog--narrow {
    width: 620px;
}

// Responsive styles
@include mq($until: desktop) {
    .dialog {
        &__title {
            font-size: 34px;
            line-height: 42px;
        }
    }
}

@include mq($until: tablet) {
    .dialog {
        &__head {
            padding-right: 0;
            padding-top: 56px;
        }

        &__title {
            font-size: 30px;
            line-height: 36px;
        }
    }

    .dialog-layout {
        padding: $margin-size--s;
    }
}

@include mq($until: phone-l) {
    .dialog {
        &__head {
            padding-right: 56px;
            padding-top: 20px;
        }
    }
}

.button {
    @include button;
    @include button--size-default;
    @include button--skin-default;
}

.button--size-s {
    @include button--size-s;
}

.button--primary {
    @include button--skin-primary;
}

.button--secondary {
    @include button--skin-secondary;
}

.button--ghost-white {
    @include button--skin-ghost--white;
}

.button--ghost-red {
    @include button--skin-ghost--red;
}

.button--faded-red {
    @include button--skin-faded--red;
}


.terms-and-conditions-block {
    margin: 0 auto;
    max-width: 600px;

    &.block {
        padding: 30px 0;
    }

    &__title {
        font-size: 22px;
        font-weight: $font-weight--bold;
        margin-bottom: 20px;
    }

    &__data-importance {
        @include cms-content;
    }

    &__link {
        font-weight: $font-weight--bold;
    }

    .terms-and-conditions-form {
        .form__row--buttons {
            justify-content: space-between;
            margin-top: 20px;
        }

        .form__button {
            margin-left: 0;
            padding: 8px 20px;
        }
    }
}

input[type=range] {
    $range-size: 1em;
    $thumb-size: 1em;
    $track-size: 0.36em;
    $progress-size: 0.36em;

    // Base styles
    -webkit-appearance: none;
    background: transparent;
    height: $range-size;
    margin: 0;
    overflow: hidden;
    padding: 0;
    vertical-align: top;
    width: 100%;

    &:focus {
        outline: none;
    }

    &::-ms-tooltip {
        display: none;
    }

    // Track styles
    &::-webkit-slider-runnable-track {
        height: $track-size;

        background: rgba($nepal-blue, .18);
        border-radius: ($track-size / 2);
    }

    &::-moz-range-track {
        height: $track-size;

        background: rgba($nepal-blue, .18);
        border-radius: ($track-size / 2);
    }

    &::-moz-range-progress {
        height: $progress-size;

        background: $brand-color;
        border-radius: ($progress-size / 2);
    }

    &::-ms-track {
        border: 0 none;
        color: transparent;
        height: $track-size;

        background: rgba($nepal-blue, .18);
        border-radius: ($track-size / 2);
    }

    &::-ms-fill-lower {
        height: $progress-size;

        background: $brand-color;
        border-radius: ($progress-size / 2);
    }

    &::-ms-fill-upper {
        background: transparent;
        border: 0 none;
        display: none;
        height: $progress-size;
    }

    // Thumb styles
    &::-webkit-slider-thumb {
        @include size($thumb-size);
        -webkit-appearance: none;
        margin: (($track-size - $thumb-size) / 2) 0;

        background: $white;
        border: 4px solid $brand-color;
        border-radius: ($thumb-size / 2);
        box-sizing: border-box;
    }

    &::-moz-range-thumb {
        @include size($thumb-size);
        border: 0 none;
        border-radius: 0;

        background: $white;
        border: 4px solid $brand-color;
        border-radius: ($thumb-size / 2);
        box-sizing: border-box;
    }

    &::-ms-thumb {
        @include size($thumb-size);
        border: 0 none;

        background: $white;
        border: 4px solid $brand-color;
        border-radius: ($thumb-size / 2);
        box-sizing: border-box;
    }
}

// Footer container.
// ---------------------------------------------------------
.layout-footer {
    flex: 0 0 auto;
    font-size: 14px;
    padding-bottom: 12px;
    position: relative;
    width: 100%;

    .layout-wrapper {
        display: flex;
        flex-wrap: wrap;
        padding: 35px 0;
        position: relative;
    }

    &__body {
        @include collapsible(14px, 'tablet-l');

        display: flex;
        width: 100%;
    }

    &__sublist {
        margin-top: $margin-size--s;

        .layout-footer__subitem {
            &:not(:last-child) {
                margin-right: $margin-size--s;
            }
        }
    }

    &__header {
        color: $brand-color--dark;
        font-weight: $font-weight--bold;
        padding-bottom: 12px;
        padding-top: 12px;

        .icon {
            display: none;
        }
    }

    &__item {
        padding-bottom: 15px;
        padding-top: 15px;
        width: 25%;

        &:not(:last-child) {
            margin-right: 30px;
        }

        &:last-child {
            border-bottom: 0;
        }

        &.more-about-lerc {
            margin-right: 0;
        }

        &.image {
            padding-bottom: 0;

            .icon {
                height: 250px;
                width: auto;
            }
        }
    }

    &__menu-item {
        line-height: 1.4;

        &.contact {
            .contact__method {
                color: $brand-color--dark;
                display: inline-block;
                font-weight: $font-weight--bold;
                min-width: 14px;
            }
        }
    }

    &__link {
        display: block;

        .icon {
            @include size(28px);
        }

        &.address {
            margin-bottom: 10px;
        }
    }

    &__image {
        @include position(absolute, 20px 0 0 null);

        .icon {
            height: 269px;
            width: auto;
        }
    }
}

.layout-subfooter {
    color: $brand-color;
    border-top: 4px solid $mystic-blue;
    font-size: 13px;
    padding: 14px 0;

    &__body {
        line-height: 1.5;
    }
}

.layout-footer-alt {
    padding-bottom: 0;

    .layout-footer {
        &__body {
            align-items: center;
            flex-direction: column;
            justify-content: center;
        }

        &__menu-item {
            &.contact {
                padding-right: 0;

                .address {
                    text-align: center;
                }
            }

            &.other {
                display: flex;

                .layout-footer__link + .layout-footer__link {
                    margin-left: 14px;
                }
            }
        }
    }
}

.layout-subfooter-alt {
    border-top: 0;
    padding-bottom: 35px;
    padding-top: 0;

    .layout-subfooter {
        &__body {
            text-align: center;
        }
    }
}

// Responsive styles
// -------------------------------------------------------
@include mq($until: desktop) {
    .layout-footer {
        &__image {
            right: 0;

            .icon {
                max-width: 170px;
            }
        }
    }
}

@include mq($until: tablet-l) {
    .layout-footer {
        padding: 0;

        .layout-wrapper {
            border: none;
            padding: 0;
        }

        &__body {
            flex-wrap: wrap;
        }

        &__header {
            padding: 12px 0;
            position: relative;

            .icon {
                $iconSize: 14px;

                @include size($iconSize);

                color: $action-color;
                display: block;
                opacity: 1;
                position: absolute;
                right: 0;
                top: calc(50% - #{$iconSize} / 2);
                transition: opacity .3s;

                &.expanded {
                    opacity: 0;
                    transform: rotate(90deg);
                }
            }
        }

        &__menu-item {
            padding-right: 20px;
        }

        &__item {
            padding: 8px 6vw;
            width: 100%;

            &:not(:last-child) {
                margin-right: 0;
            }
        }

        &__sublist {
            display: none;
            margin-top: 0;
            padding-bottom: $margin-size--s;
        }

        &__subitem {
            margin-bottom: $margin-size--xs;
        }

        &__image {
            display: none;
        }
    }

    .layout-footer-alt {
        .layout-wrapper {
            border-left: 3vw solid transparent;
            border-right: 3vw solid transparent;
            padding: 35px 0;
        }
    }
}

@include mq($until: phone-l) {
    .layout-subfooter {
        &__body {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            text-align: center;
        }

        &__tacs-link {
            order: 1;
            width: 100%;
        }

        .divider {
            display: none;
        }
    }

    .layout-footer-alt {
        .layout-wrapper {
            padding-bottom: 20px;
        }

        .layout-footer {
            &__menu-item {
                justify-content: center;

                &.contact {
                    .layout-footer__link {
                        &.address {
                            margin-bottom: 20px;
                        }

                        &.website {
                            width: 100%;
                        }

                        &.email {
                            margin-left: 14px;
                        }
                    }
                }

                &.other {
                    flex-wrap: wrap;

                    .layout-footer__link {
                        text-align: center;
                    }

                    .layout-footer__link + .layout-footer__link {
                        margin-left: 0;
                    }
                }
            }
        }
    }

    .layout-subfooter {
        &__body {
            //justify-content: center;
        }
    }
}

.previous-searches-block {
    &__introduction {
        margin-bottom: 40px;
    }

    .table {
        border-collapse: collapse;
        font-size: 14px;
        font-weight: $font-weight;
        margin: 0 auto;
        max-width: 1170px;
        width: 100%;

        &__header {
            font-weight: $font-weight;

            &.sortable {
                .table__link {
                    &::after {
                        @include size(12px);

                        display: inline-block;
                        content: url("/images/controls/angle-inactive.svg");
                        margin-left: 8px;
                        transition: transform .2s ease;
                    }
                }

                &.active {
                    font-weight: $font-weight--bold;

                    .table__link {
                        &::after {
                            content: url("/images/controls/angle-active.svg");
                        }
                    }
                }

                &.asc {
                    .table__link {
                        &::after {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }

        thead {
            color: $brand-color--dark;
            background: $mystic-blue;
        }

        tbody {
            color: $eggplant-purple;
        }

        &__row, &__header {
            text-align: left;
        }

        &__row {
            &:not(:last-child) {
                border-bottom: 2px solid $mystic-blue;
            }
        }

        &__header {
            line-height: 1.2;
            padding: 12px 0;
        }

        &__header, &__cell {
            &:first-child {
                padding-left: 6vw;
            }

            &:last-child {
                padding-right: 6vw;
            }

            &:not(:last-child) {
                padding-right: 36px;
            }
        }

        &__cell {
            padding: 12px 0;
        }

        &__link {
            color: $brand-color--dark;
            position: relative;

            &::after {
                @include position(absolute, 3px -20px null null);
            }
        }

        .date {
            min-width: calc(6vw + 104px);
        }

        .grid-reference {
            min-width: 140px;
        }

        .project-name {
            min-width: 170px;
        }

        .invoice-number {
            min-width: 130px;

            .icon {
                @include size(15px);

                margin-left: 3px;
                margin-top: 1px;
            }
        }

        .downloads {
            min-width: 380px;

            .button {
                @include button--size(27px, 13px);

                display: inline-block;
                padding: 5px 16px;
                text-transform: capitalize;

                &:not(:last-child) {
                    margin-right: 6px;
                }

                .icon {
                    @include size(14px);

                    margin-left: 4px;
                }
            }
        }

        .search-again {
            text-align: center;
            max-width: 100px;

            .icon {
                @include size(28px);
            }
        }
    }
}

// Responsive styles
@include mq($from: 1170px) {
    .previous-searches-block {
        .table {
            max-width: 1024px;

            .date {
                padding-left: 20px;
                min-width: 120px;
            }

            .search-again {
                padding-right: 20px;
            }
        }
    }
}

@include mq($from: 1100px) {
    .previous-searches-block {
        .table {
            .grid-label {
                display: none;
            }
        }
    }
}

@include mq($until: 1099px) {
    .previous-searches-block {
        .table {
            display: block;

            thead {
                display: none;
            }

            tbody {
                border: 0 solid transparent;
                border-left-width: 6vw;
                border-right-width: 6vw;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin-left: -10px;
                margin-right: -10px;
            }

            &__row {
                border: 2px solid $mystic-blue;
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                flex-wrap: wrap;
                margin: 0 10px 20px 10px;
                min-width: 285px;
                max-width: calc(50% - 20px);
            }

            .date,
            .grid-reference,
            .invoice-number,
            .project-name,
            .downloads,
            .search-again {
                min-width: 100%;
                padding: 0 20px;
                width: 100%;
            }

            .date,
            .grid-reference,
            .project-name {
                background: $mystic-blue;
            }

            .date {
                font-size: 13px;
                padding-bottom: 2px;
            }

            .grid-reference {
                font-size: 24px;
                font-weight: $font-weight--bold;
            }

            .project-name {
                font-size: 13px;
                font-weight: $font-weight--bold;
                padding-bottom: 22px;

                .grid-label {
                    font-weight: $font-weight;
                }
            }

            .invoice-number {
                align-items: center;
                display: flex;
                font-size: 13px;
                font-weight: $font-weight--bold;
                padding-bottom: 14px;
                padding-top: 14px;

                .icon {
                    @include size(22px);

                    order: 1;
                }

                .grid-label {
                    order: 2;
                    font-weight: $font-weight;
                    margin-left: 8px;
                }

                .invoice {
                    align-items: center;
                    display: flex;
                    order: 3;
                    margin-left: 4px;
                }
            }

            &__cell {
                &:first-child {
                    padding-top: 20px;
                }

                &:last-child {
                    padding-bottom: 20px;
                }
            }

            .downloads {
                display: flex;
                flex-direction: column;
                margin-bottom: 16px;
                width: 100%;

                .button {
                    @include button--size(39px, 14px);

                    &:not(:last-child) {
                        margin-right: 0;
                    }

                    .icon {
                        @include size(16px);

                        margin-left: 6px;
                    }
                }

                .button + .button {
                    margin-top: 10px;
                }
            }

            .search-again {
                &__link {
                    align-items: center;
                    display: flex;
                    font-size: 16px;
                    font-weight: $font-weight--bold;
                    justify-content: center;

                    .icon {
                        @include size(32px);

                        margin-right: 10px;
                    }
                }
            }
        }
    }
}

@include mq($until: 705px) {
    .previous-searches-block {
        .table {
            &__row {
                max-width: 100%;
                width: 100%;
            }
        }
    }
}

.section,
.block {
    padding: 50px 0;

    &--shaded {
        background: $mystic-blue;
    }
}

// Responsive styles
@include mq($until: tablet) {
    .section,
    .block {
        padding: 40px 0;
    }
}

.privacy-policy {

    .mk-fancy-table {
        margin-bottom: 20px;
        overflow: hidden;

        table {
            border-collapse: collapse;
            border-radius: 3px;
            margin: 0;
            overflow: hidden;
            padding: 0;
            text-align: left;
            width: 100%;
        }

        td {
            border-left: 1px solid $white;
            padding: 12px;
            text-align: center;
        }

        th {
            background-color: #b9216c !important;
            color: $white;
            font-size: 14px;
            font-weight: bold;
            padding: 18px 12px;
            text-align: center;
            text-transform: uppercase;
        }

        tr {
            $even-color: rgba(0, 0, 0, .02);
            background-color: $even-color;

            &:nth-child(odd) {
                $odd-color: rgba(0, 0, 0, .04);
                background-color: $odd-color;
            }
        }
    }

    .mk-fancy-title {
        color: #b9216c;
        font-size: 16px;
        font-weight: bold;
        padding-bottom: 18px;
        padding-top: 30px;
    }

    .mk-text-block {
        p {
            font-size: 16px;
            line-height: 1.66em;
            margin-bottom: 20px;
        }

        ul {
            list-style-type: disc;
            margin-left: 1.5em;

            li {
                margin-left: .85em;
            }
        }

        ol,
        ul {
            margin-bottom: 1em;
        }

        li {
            display: list-item;
            text-align: -webkit-match-parent;
        }

        strong,
        th,
        thead td {
            font-weight: 700;
        }

        strong {
            color: #b9216c;
        }
    }
}

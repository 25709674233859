.ellipsis {
    display: inline-block;
    height: 1em * $line-height--s;
    margin-right: -.3em;
    overflow: hidden;
    padding-top: 1em * $line-height--s;
    position: relative;
    vertical-align: top;
    width: 1em;

    &::after {
        @include position(absolute, 0 0 null 0);
        content: '…';
        text-align: right;
    }
}

.project-results-block {
    border-bottom: 4px solid $mystic-blue;

    .project-summary-block__map {
        top: -76px;
    }

    &__summary {
        margin-top: -64px;
    }

    &__shadow-box {
        @include collapsible($iconSize: 14px, $breakpoint: 'tablet-l');

        align-self: flex-start;
        background: $white;
        max-width: 630px;
        min-width: 200px;
        padding: 16px 20px 24px 20px;
    }

    .shadow-box {
        border-radius: 12px;
        border-right: 3px solid $mystic-blue;
        border-bottom: 3px solid $mystic-blue;
        border-left: 1px solid $mystic-blue;

        &__title {
            color: $brand-color--dark;
            font-size: 18px;
            font-weight: $font-weight--bold;
            margin-bottom: 12px;
        }

        &__list {
            columns: 2;
        }

        &__item {
            color: $mountbatten-pink;
            font-size: 14px;
        }

        .shadow-box__item + .shadow-box__item {
            margin-top: 8px;
        }

        &__count {
            color: $brand-color--dark;
            font-weight: $font-weight--bold;
        }
    }

    &__information {
        color: $eggplant-purple;
        font-size: 14px;
        line-height: 1.4;
        margin-top: 24px;
    }

    &__report-documents {
        margin-top: 26px;

        .report-documents__title {
            font-size: 18px;
            font-weight: $font-weight--bold;
            margin-bottom: 12px;
        }

        .button {
            margin-top: 10px;
            text-transform: capitalize;

            .icon {
                @include size(14px);
            }

            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }

    .info-box {
        margin-top: 36px;
        max-width: 550px;
    }
}

// Responsive styles
@include mq($until: desktop) {
    .project-results-block {
        .project-summary-block__map {
            top: -112px;
        }
    }
}

@include mq($until: tablet-l) {
    .project-results-block {
        padding-top: 364px;

        .project-summary-block__map {
            top: -330px;
        }

        .shadow-box {
            border: none;
            margin-right: 0;
            max-width: 100%;
            padding: 0;

            &__list {
                columns: 1;
            }

            .collapsible__item {
                border-bottom: 0;
            }

            &__item {
                padding: 3px 0;
            }

            &__title {
                border-bottom: 2px solid $mystic-blue;
                padding: 6px 0;
            }
        }
    }
}

@include mq($until: tablet) {
    .project-results-block {
        padding-bottom: 10px;
        padding-top: 346px;

        .project-summary-block__map {
            top: -312px;
        }

        .report-documents {
            .button {
                margin-right: 0;
                margin-top: 6px;
                width: 100%;
            }
        }
    }
}

.flash {
    font-size: 18px;
    font-weight: bold;
    max-width: 650px;
    padding: 30px 60px 30px 30px;
    text-align: center;
    width: 100%;

    &__title {
        font-size: 24px;
        margin-bottom: 12px;
    }

    &__body {
        border-top: 2px solid $mystic-blue;
        padding-top: 12px;
    }
}
